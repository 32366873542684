var content = require("!!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/resolve-url-loader/index.js??ref--5-oneOf-2-2!../../node_modules/postcss-loader/src/index.js??postcss!../../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-2-4!./theme-vendors.css");

if (typeof content === 'string') {
  content = [[module.id, content, '']];
}

var options = {}

options.insert = "head";
options.singleton = false;

var update = require("!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js")(content, options);

if (content.locals) {
  module.exports = content.locals;
}
