
import "../assets/js/vendors/modernizr.min.js";

import "../assets/css/vendors/liquid-icon/liquid-icon.min.css";
import "../assets/css/vendors/font-awesome/css/font-awesome.min.css";
import "../assets/css/theme-vendors.css";
import "../assets/css/theme.css";
import "../assets/css/multiconcept.css";
import "../assets/css/customizations.scss";

import { tsx } from "springtype/web/vdom";
import { HomepageComponent } from "./index";

export default (cmp: HomepageComponent) => {

  return <fragment>

    <div id="wrap">

      <header class="main-header main-header-overlay">

        <div class="mainbar-wrap">
          <div class="megamenu-hover-bg"></div>
          <div class="container-fluid mainbar-container">
            <div class="mainbar">
              <div class="row mainbar-row align-items-lg-stretch px-4">

                <div class="col">

                  <div class="navbar-header">
                    <a class="navbar-brand" href="index.html" rel="home">
                      <span class="navbar-brand-inner">
                        <img class="logo-dark" src={require("../assets/img/logo/logo-light.svg")} alt="healing.earth logo" />
                        <img class="logo-light" src={require("../assets/img/logo/logo-light.svg")} alt="healing.earth logo" />
                        <img class="logo-sticky" src={require("../assets/img/logo/logo-light.svg")} alt="healing.earth logo" />
                        <img class="mobile-logo-default" src={require("../assets/img/logo/logo-light.svg")} alt="healing.earth logo" />
                        <img class="logo-default" src={require("../assets/img/logo/logo-light.svg")} alt="healing.earth logo" />
                      </span>

                      <div class="dropdown">
                        <button class="language-chooser dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          🇺🇸
                      </button>
                        <div class="language-chooser dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item" href="javascript:" onclick={() => cmp.changeLanguage('de')}>🇩🇪 Deutsch</a>
                          <a class="dropdown-item" href="javascript:" onclick={() => cmp.changeLanguage('es')}>🇪🇸 Español</a>
                          <a class="dropdown-item" href="javascript:" onclick={() => cmp.changeLanguage('fr')}>🇫🇷 Français</a>
                          <a class="dropdown-item" href="javascript:" onclick={() => cmp.changeLanguage('it')}>🇮🇹 Italiano</a>
                          <a class="dropdown-item" href="javascript:" onclick={() => cmp.changeLanguage('ru')}>🇷🇺 Русский</a>
                          <a class="dropdown-item" href="javascript:" onclick={() => cmp.changeLanguage('hi')}>🇮🇳 हिन्दी</a>
                        </div>
                      </div>
                    </a>

                    <button type="button" class="navbar-toggle collapsed nav-trigger style-mobile" data-toggle="collapse" data-target="#main-header-collapse" aria-expanded="false" data-changeclassnames='{ "html": "mobile-nav-activated overflow-hidden" }'>
                      <span class="sr-only">Toggle navigation</span>
                      <span class="bars">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                      </span>
                    </button>
                  </div>

                </div>

                <div class="col text-right">

                  <div class="collapse navbar-collapse navbar-visible-ontoggle collapsed" id="main-header-collapse" aria-expanded="false" role="tablist">

                    <span>
                      <ul class="main-nav main-nav-hover-underline-2 nav align-items-lg-stretch justify-content-lg-end" data-submenu-options='{"toggleType":"fade","handler":"mouse-in-out"}'>

                        <li>
                          <a href="#intro">
                            <span class="link-icon"></span>
                            <span class="link-txt">
                              <span class="link-ext"></span>
                              <span class="txt">Power of Many</span>
                            </span>
                          </a>
                        </li>

                        <li>
                          <a href="#save-trees">
                            <span class="link-icon"></span>
                            <span class="link-txt">
                              <span class="link-ext"></span>
                              <span class="txt">Save Trees</span>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="#consumption">
                            <span class="link-icon"></span>
                            <span class="link-txt">
                              <span class="link-ext"></span>
                              <span class="txt">Consumption</span>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="#diet">
                            <span class="link-icon"></span>
                            <span class="link-txt">
                              <span class="link-ext"></span>
                              <span class="txt">Diet</span>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="#housing">
                            <span class="link-icon"></span>
                            <span class="link-txt">
                              <span class="link-ext"></span>
                              <span class="txt">Housing</span>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="#transportation">
                            <span class="link-icon"></span>
                            <span class="link-txt">
                              <span class="link-ext"></span>
                              <span class="txt">Transportation</span>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </span>

                  </div>

                  <div class="header-module">
                    <button class="nav-trigger collapsed style-1 fill-solid scheme-light txt-left main-nav-trigger" role="button"
                      type="button" data-toggle="collapse" data-target="#main-header-collapse" aria-expanded="false" aria-controls="main-header-collapse"
                      data-changeclassnames='{ "html": "overflow-hidden" }'>
                      <span class="bars">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                      </span>
                    </button>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

      </header>

      <main id="content" class="content bg-black" data-liquid-stack="true" data-stack-options='{"navigation":true, "prevNextButtons":true, "pageNumber":true, "effect":"fadeScale"}'>

        <section id="intro" class="vc_row py-6 bg-cover d-flex flex-wrap align-items-center" data-tooltip="The Power of Many" style={"background-image: url(" + require("../assets/img/markus-spiske-hp86NCu8hok-unsplash.jpg") + ")"}>
          <div class="container">
            <div class="row">

              <div
                class="lqd-column col-md-6 col-md-offset-3"
                data-custom-animations="true"
                data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":"1600","delay":100,"easing":"easeOutQuint","direction":"forward","initValues":{"translateY":80,"rotateZ":5,"opacity":0},"animations":{"translateY":0,"rotateZ":0,"opacity":1}}'>

                <img src={require('../assets/img/scifu-fc.svg')} class="scifu" />

                <div class="ld-fancy-heading mask-text">
                  <h6
                    class="mb-0 h2 text-white"
                    data-split-text="true"
                    data-split-options='{"type":"lines"}'>
                    <span class="ld-fh-txt"> with the</span>
                  </h6>
                </div>
                <div class="ld-fancy-heading mask-text">
                  <h2
                    class="mb-0 lh-1 text-white"
                    data-fittext="true"
                    data-fittext-options='{"compressor":0.4,"maxFontSize": 55,"minFontSize": 60}'
                    data-split-text="true"
                    data-split-options='{"type":"lines"}'>
                    <span class="ld-fh-txt">Power of Many</span>
                  </h2>
                </div>

                <div class="row">

                  <div class="lqd-column col-md-3 col-xs-4">

                    <div class="lqd-h-sep w-70">
                      <span class="lqd-h-sep-inner"></span>
                    </div>

                  </div>

                  <div class="lqd-column col-md-9">

                    <div class="ld-fancy-heading mask-text text-right">
                      <h6
                        class="mb-0 h2 text-white"
                        data-split-text="true"
                        data-split-options='{"type":"lines"}'>
                        <span class="ld-fh-txt"> we'll&nbsp;counter climate&nbsp;change</span>
                      </h6>
                    </div>

                  </div>

                  <div class="lqd-column col-md-12 mt-40 text-center">

                    <div class="ld-fancy-heading mask-text">
                      <p
                        class="mb-5 h5 text-white"
                        data-split-text="true"
                        data-split-options='{"type":"lines"}'>
                        <span class="ld-fh-txt">
                          Our habits and the neglect of personal responsibility are the keys to inaction. This time, we can't wait for the world's
                        rulers to take action &ndash; action depends on us. Mass extinction can only be countered by a behaviour change of masses.
                        <br /><br />
                          <strong>&nbsp;healing.earth shows effective ways.</strong>
                        </span>
                      </p>

                    </div>

                    <a class="btn btn-default text-uppercase btn-bordered border-thin btn-white-fade-border font-size-14 font-weight-bold ltr-sp-1 mb-2" data-toggle="modal" data-target="#is-climate-change-real-modal">
                      <span>
                        <span class="btn-txt">Mass Extinction!?</span>
                      </span>
                    </a>
                    <a class="btn btn-solid text-uppercase btn-bordered border-thin font-size-14 font-weight-bold ltr-sp-1 mb-2" href="#save-trees">
                      <span>
                        <span class="btn-txt">Take Action</span>
                      </span>
                    </a>

                  </div>

                </div>

              </div>

            </div>
          </div>
        </section>

        <section id="save-trees" class="vc_row py-6 bg-cover justify-content-end py-5" data-tooltip="Save Trees" style={"background-image: url(" + require("../assets/img/matthew-smith-Rfflri94rs8-unsplash.jpg") + ")"}>
          <div class="container">
            <div class="row d-flex flex-wrap align-items-end pb-md-12 mb-md-7" style="margin-top:50px; margin-bottom: 0">

              <div class="lqd-column col-lg-2 col-md-2 text-md-right">


                <div class="ld-fancy-heading mask-text">
                  <img src={require('../assets/img/aron_profile.jpg')} class="founder-img" /><br />
                  <font color="#fff">Aron Homberg</font><br />
                  of healing.earth
                <br />
                  <br />
                  <br />
                </div>

                <div class="lqd-h-sep w-25 ml-auto">
                  <span class="lqd-h-sep-inner"></span>
                </div>

                <div class="ld-fancy-heading mask-text">
                  <h2
                    class="text-white mb-md-0"
                    data-fittext="true"
                    data-fittext-options='{"compressor":0.3,"maxFontSize": 62,"minFontSize": "currentFontSize"}'
                    data-custom-animations="true"
                    data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1800","startDelay":"200","delay":100,"easing":"easeOutQuint","direction":"forward","initValues":{"translateY":150,"rotateZ":-5},"animations":{"translateY":0,"rotateZ":0}}'
                    data-split-text="true"
                    data-split-options='{"type":"lines"}'>
                    <span class="ld-fh-txt">save trees</span>
                  </h2>
                </div>

              </div>

              <div class="lqd-column col-md-4 col-lg-offset-1 pb-3">

                <div class="ld-fancy-heading mask-text">

                  <p
                    class="text-white h4"
                    data-split-text="true"
                    data-split-options='{"type":"lines"}'
                    data-custom-animations="true"
                    data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"200","delay":"120","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":50,"rotateZ":5},"animations":{"translateY":0,"rotateZ":0}}'>
                    <span class="ld-fh-txt">
                      The trouble with trees
                  </span>

                  </p>

                  <p
                    class="text-white h5"
                    data-split-text="true"
                    data-split-options='{"type":"lines"}'
                    data-custom-animations="true"
                    data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"200","delay":"120","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":50,"rotateZ":5},"animations":{"translateY":0,"rotateZ":0}}'>
                    <span class="ld-fh-txt">
                      Prof. Dr. Tom Crowther and his team at ETH Zurich found that we'd need to plant 1.2 trillion trees
                      in order to stop climate change for one decade. So let's make planting a habit?
                    Yes and no. Trees need 40 to 60 years to lower CO2 levels &ndash; but our goal is 0t by 2050.
                    </span>

                  </p>

                  <p
                    class="text-white h5"
                    data-split-text="true"
                    data-split-options='{"type":"lines"}'
                    data-custom-animations="true"
                    data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"200","delay":"120","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":50,"rotateZ":5},"animations":{"translateY":0,"rotateZ":0}}'>
                    <span class="ld-fh-txt">

                      <strong>Planting isn't easy. </strong>
                      Seeds need the right soil; biodiversity is crucial; growing
                      requires knowledge, time and money. Nature recovers best, when left alone.
                  </span>

                  </p>

                </div>

              </div>

              <div class="lqd-column col-md-4 col-lg-3 col-lg-offset-1 pb-3" data-custom-animations="true" data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":"1600","startDelay":"500","delay":"150","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":49,"rotateZ":5,"opacity":0},"animations":{"translateY":0,"rotateZ":0,"opacity":1}}'>

                <div class="ld-fancy-heading text-uppercase">
                  <p
                    class="mb-0 font-size-18 font-weight-bold text-white plant-trees"
                    data-split-text="true"
                    data-split-options='{"type":"lines"}'>
                    <span class="ld-fh-txt"> Healing Earth's own NGO</span>
                  </p>
                </div>

                <div class="ld-fancy-heading mb-3">
                  <p
                    class="mb-0 font-size-18 text-white"
                    data-split-text="true"
                    data-split-options='{"type":"lines"}'>
                    <span class="ld-fh-txt">
                      We're founding a non-profit NGO in India. We want to make sure
                      that for every cent donated, trees will be saved and knowledge on
                      climate change is spread globally. 
                      If you love this: Please <strong>donate</strong> to empower us.
                  </span>
                  </p>

                </div>

                <a href="https://www.youtube.com/watch?v=EXkbdELr4EQ" style="margin-top:30px;margin-left:0px" class="btn btn-naked text-uppercase fresco btn-icon-left btn-icon-xlg btn-icon-circle btn-icon-solid btn-icon-ripple font-size-13 font-weight-bold ltr-sp-1">
                  <span>
                    <span class="btn-txt text-white">The trouble <br /> with trees (9:44)</span>
                    <span class="btn-icon font-size-20"><i class="fa fa-play"></i></span>
                  </span>
                </a>

                <a href="https://www.youtube.com/watch?v=LfcHgUxzgqk" style="margin-top:30px;margin-left:0px" class="btn btn-naked text-uppercase fresco btn-icon-left btn-icon-xlg btn-icon-circle btn-icon-solid btn-icon-ripple font-size-13 font-weight-bold ltr-sp-1">
                  <span>
                    <span class="btn-txt text-white">Planting: A critical <br />view (9:41)</span>
                    <span class="btn-icon font-size-20"><i class="fa fa-play"></i></span>
                  </span>
                </a>


              </div>

            </div>
          </div>
        </section>

        <section id="consumption" class="vc_row py-6 bg-cover" data-tooltip="Consumption" style={"background-image: url(" + require("../assets/img/ethan-jameson-RtyniRlSatw-unsplash1.jpg") + ");"}>
          <div class="container">
            <div class="row">

              <div class="lqd-column col-lg-9 col-md-12" style="margin-top:30px">

                <div class="ld-fancy-heading mask-text">
                  <h2
                    class="mb-40 h3 font-weight-bold lh-85 text-white"
                    data-fittext="true"
                    data-fittext-options='{"compressor":0.35,"maxFontSize": 45,"minFontSize": 30}'
                    data-split-text="true"
                    data-split-options='{"type":"lines"}'
                    data-custom-animations="true"
                    data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","delay":"160","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":175,"rotateZ":5},"animations":{"translateY":0,"rotateZ":0}}'>
                    <span class="ld-fh-txt">Less Shopping for Future</span>
                  </h2>
                </div>

                <div class="row">

                  <div class="lqd-column col-lg-12 col-md-12">

                    <div class="ld-fancy-heading mask-text">
                      <p
                        class="mb-35 h4 text-white"
                        data-split-text="true"
                        data-custom-animations="true"
                        data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"150","delay":"160","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":60,"rotateZ":5},"animations":{"translateY":0,"rotateZ":0}}'
                        data-split-options='{"type":"lines"}'>
                        <span class="ld-fh-txt">Conumption drives climate change most. The wealthier we get, the more we tend to destroy our environment.</span>
                      </p>
                    </div>

                    <div class="lqd-h-sep w-15 mb-45">
                      <span class="lqd-h-sep-inner"></span>
                    </div>

                  </div>

                  <div class="lqd-column h5 col-md-6" style="margin-top:-5px">

                    <div class="ld-fancy-heading mask-text">
                      <p
                        class="text-white"
                        data-split-text="true"
                        data-split-options='{"type":"lines"}'
                        data-custom-animations="true"
                        data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"300","delay":"120","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":50,"rotateZ":5},"animations":{"translateY":0,"rotateZ":0}}'>
                        <span class="ld-fh-txt">Energy and material is needed for every product's manufacturing process. Industrial combustion and a reliable power industry become inevitable and international transport is necessary to ship foreign goods. Globalization drives ocean acidification as the evil twin of climate change.

                        Mind that in 2009, the "Great Recession" caused a dip of 8.5% in U.S. CO2 emissions.</span>
                      </p>
                    </div>

                  </div>

                  <div class="lqd-column h5 col-md-6" style="margin-top:-5px">

                    <div class="ld-fancy-heading mask-text">
                      <p
                        class="text-white"
                        data-split-text="true"
                        data-split-options='{"type":"lines"}'
                        data-custom-animations="true"
                        data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"300","delay":"120","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":50,"rotateZ":5},"animations":{"translateY":0,"rotateZ":0}}'>
                        <span class="ld-fh-txt"><strong>Mindful consumption &amp; minimalism:</strong>
                          <ul>
                            <li><strong>Ask yourself:</strong> "Do I really need this?" or "Can I get this used?"</li>
                            <li>Prefer to buy products manufactured locally.</li>
                            <li>If you buy &ndash; buy carbon-offsetted products.</li>
                            <li><strong>The best product is self-made or upcycled.</strong></li>
                          </ul>

                        </span>
                      </p>
                    </div>

                    <a href="https://www.youtube.com/watch?v=jXCZ9MFBBp4" style="margin-top:30px;margin-left:0px" class="btn btn-naked text-uppercase fresco btn-icon-left btn-icon-xlg btn-icon-circle btn-icon-solid btn-icon-ripple font-size-13 font-weight-bold ltr-sp-1">
                      <span>
                        <span class="btn-txt text-white">The Carbon footprint<br />of consumption (10:50)</span>
                        <span class="btn-icon font-size-20"><i class="fa fa-play"></i></span>
                      </span>
                    </a>

                  </div>


                </div>

              </div>

            </div>
          </div>
        </section>

        <section id="diet" class="vc_row py-6 bg-cover" data-tooltip="Diet" style={"background-image: url(" + require("../assets/img/nadine-primeau-l5Mjl9qH8VU-unsplash.jpg") + ");"}>
          <div class="container">
            <div class="row">

              <div class="lqd-column col-lg-12 col-md-12" data-custom-animations="true" data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":"1600","startDelay":"100","delay":"160","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":85,"rotateZ":2,"opacity":0},"animations":{"translateY":0,"rotateZ":0,"opacity":1}}'>

                <div class="ld-fancy-heading" style="margin-top:50px">
                  <h2
                    class="mb-55 text-white ltr-sp--05"
                    data-fittext="true"
                    data-split-text="true"
                    data-split-options='{"type":"lines"}'
                    data-fittext-options='{"compressor":0.375,"maxFontSize": 50,"minFontSize": 40}'>
                    <span class="ld-fh-txt">Change your Diet for Future</span>
                  </h2>
                </div>

                <div class="lqd-h-sep w-15 mb-45">
                  <span class="lqd-h-sep-inner"></span>
                </div>

                <div class="row">

                  <div class="lqd-column col-md-4">

                    <div class="ld-fancy-heading mask-text">
                      <p
                        class="mb-35 h4 text-white"
                        data-split-text="true"
                        data-custom-animations="true"
                        data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"150","delay":"160","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":60,"rotateZ":5},"animations":{"translateY":0,"rotateZ":0}}'
                        data-split-options='{"type":"lines"}'>
                        <span class="ld-fh-txt">Animal agriculture is responsible for 13–18% of human-caused greenhouse gas emissions globally, 20-33% of fresh water use and 30-90% of local biodiversity loss.</span>
                      </p>
                    </div>
                  </div>

                  <div class="lqd-column col-md-5">

                    <div class="ld-fancy-heading mask-text">
                      <p
                        class="mb-35 h5 text-white"
                        data-split-text="true"
                        data-custom-animations="true"
                        data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"150","delay":"160","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":60,"rotateZ":5},"animations":{"translateY":0,"rotateZ":0}}'
                        data-split-options='{"type":"lines"}'>
                        <span class="ld-fh-txt">

                          <strong>...and plays a role in health crisis:</strong>
                          <ul>
                            <li>Vegan diets lower cancer risk</li>
                            <li>Vegan diets lower blood sugar levels</li>
                            <li>Vegan diets lower the risk of heart disease</li>
                            <li>Vegan diets may counter dementia</li>
                            <li>But vegan diets may increase stroke risk</li>
                            <li>Vegetarian diets cause vitamin B-12 deficiency</li>
                          </ul>

                          We don't have to be vegan or vegetarian, but eliminating like 90% of your meat intake
                          would help alot. Every gram of meat not eaten helps eliminating animal cruelty.
                      </span>
                      </p>
                    </div>
                  </div>


                  <div class="lqd-column col-md-3">
                    <div class="ld-fancy-heading mask-text text-uppercase">
                      <h6
                        class="mb-4 font-size-14 ltr-sp-1 lh-175 text-white"
                        data-split-text="true"
                        data-split-options='{"type":"lines"}'>
                        <span class="ld-fh-txt">What if the world <br /> went vegetarian?</span>
                      </h6>
                    </div>


                    <a href="https://www.youtube.com/watch?v=ANUoAdXfA60" style="margin-top:-10px;" class="btn btn-naked text-uppercase fresco btn-icon-left btn-icon-xlg btn-icon-circle btn-icon-solid btn-icon-ripple font-size-13 font-weight-bold ltr-sp-1">
                      <span>
                        <span class="btn-txt text-white">Watch (3:46)</span>
                        <span class="btn-icon font-size-20"><i class="fa fa-play"></i></span>
                      </span>
                    </a>
                    <br /><br />


                    <div class="ld-fancy-heading mask-text text-uppercase">
                      <h6
                        class="mb-4 font-size-14 ltr-sp-1 lh-175 text-white"
                        data-split-text="true"
                        data-split-options='{"type":"lines"}'>
                        <span class="ld-fh-txt">The diet that  <br /> helps fight climate change</span>
                      </h6>
                    </div>



                    <a href="https://www.youtube.com/watch?v=nUnJQWO4YJY" style="margin-top:-10px;" class="btn btn-naked text-uppercase fresco btn-icon-left btn-icon-xlg btn-icon-circle btn-icon-solid btn-icon-ripple font-size-13 font-weight-bold ltr-sp-1">
                      <span>
                        <span class="btn-txt text-white">Watch (5:39)</span>
                        <span class="btn-icon font-size-20"><i class="fa fa-play"></i></span>
                      </span>
                    </a>
                  </div>


                </div>

              </div>

            </div>
          </div>
        </section>

        <section id="housing" class="vc_row py-5 bg-cover bg-center" data-tooltip="Housing" style={"background-image: url(" + require("../assets/img/breno-assis-r3WAWU5Fi5Q-unsplash.jpg") + ");"}>
          <div class="container">
            <div class="row">

              <div class="lqd-column col-lg-7 col-md-9 col-lg-offset-5 col-md-offset-3 text-right">

                <div class="ld-fancy-heading">
                  <h2
                    class="h3 lh-85 ltr-sp--05 font-weight-bold text-white"
                    data-fittext="true"
                    data-fittext-options='{"compressor":0.375,"maxFontSize": 80,"minFontSize": 60}'
                    data-split-text="true"
                    data-split-options='{"type":"lines"}'
                    data-custom-animations="true"
                    data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","delay":"160","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":150,"rotateZ":-5,"opacity":0},"animations":{"translateY":0,"rotateZ":0,"opacity":1}}'>
                    <span class="ld-fh-txt"> Housing</span>
                  </h2>
                </div>

                <div class="lqd-h-sep w-10 ml-auto mb-45">
                  <span class="lqd-h-sep-inner"></span>
                </div>

                <div class="row">

                  <div class="lqd-column col-md-9 col-md-offset-3">

                    <div class="ld-fancy-heading mask-text">
                      <p
                        class="mb-5 h4 text-white"
                        data-split-text="true"
                        data-custom-animations="true"
                        data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"300","delay":"160","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":60,"rotateZ":-5},"animations":{"translateY":0,"rotateZ":0}}'
                        data-split-options='{"type":"lines"}'>
                        <span class="ld-fh-txt">Housing contributes to <a href="https://theconversation.com/5-charts-show-how-your-household-drives-up-global-greenhouse-gas-emissions-119968" target="_blank">33-35%</a> of a families carbon footprint.</span>
                      </p>

                      <p
                        class="mb-5 h5 text-white"
                        data-split-text="true"
                        data-custom-animations="true"
                        data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"300","delay":"160","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":60,"rotateZ":-5},"animations":{"translateY":0,"rotateZ":0}}'
                        data-split-options='{"type":"lines"}'>
                        <span class="ld-fh-txt">
                          To lower your housings greenhouse gas footprint:<br /><br />
                          &ndash; Invest in proper housing insulation<br />
                          &ndash; Change to renewable power sources<br />
                          &ndash; Carbon-offset your power consumption<br />
                        </span>
                      </p>
                    </div>

                  </div>

                  <div class="lqd-column col-md-9 col-md-offset-3">
                    <div class="ld-fancy-heading mask-text">

                      <a href="https://www.youtube.com/watch?v=2958AslmwYQ" style="margin-top:-30px;margin-left:0px" class="btn btn-naked text-uppercase fresco btn-icon-left btn-icon-xlg btn-icon-circle btn-icon-solid btn-icon-ripple font-size-13 font-weight-bold ltr-sp-1">
                        <span>
                          <span class="btn-txt text-white">Address<br />emissions (1:04)</span>
                          <span class="btn-icon font-size-20"><i class="fa fa-play"></i></span>
                        </span>
                      </a>

                    </div>
                  </div>

                </div>

              </div>

            </div>
          </div>
        </section>

        <section id="transportation" class="vc_row py-6 bg-cover" data-tooltip="Transportation" style={"background-image: url(" + require("../assets/img/jake-blucker-ZGnC2gOvzKw-unsplash.jpg") + ");"}>
          <div class="container">
            <div class="row">

              <div class="lqd-column col-lg-9 col-md-12" style="margin-top:30px">

                <div class="ld-fancy-heading mask-text">
                  <h2
                    class="mb-40 h3 font-weight-bold lh-85 text-white"
                    data-fittext="true"
                    data-fittext-options='{"compressor":0.35,"maxFontSize": 45,"minFontSize": 30}'
                    data-split-text="true"
                    data-split-options='{"type":"lines"}'
                    data-custom-animations="true"
                    data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","delay":"160","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":175,"rotateZ":5},"animations":{"translateY":0,"rotateZ":0}}'>
                    <span class="ld-fh-txt">Transportation</span>
                  </h2>
                </div>

                <div class="row">

                  <div class="lqd-column col-lg-12 col-md-12">

                    <div class="ld-fancy-heading mask-text">
                      <p
                        class="mb-35 h4 text-white"
                        data-split-text="true"
                        data-custom-animations="true"
                        data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"150","delay":"160","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":60,"rotateZ":5},"animations":{"translateY":0,"rotateZ":0}}'
                        data-split-options='{"type":"lines"}'>
                        <span class="ld-fh-txt">
                          Transportation contributes to <a href="https://theconversation.com/5-charts-show-how-your-household-drives-up-global-greenhouse-gas-emissions-119968">17-29%</a> of a households carbon footprint.
                        </span>
                      </p>
                    </div>

                    <div class="lqd-h-sep w-15 mb-45">
                      <span class="lqd-h-sep-inner"></span>
                    </div>

                  </div>

                  <div class="lqd-column h5 col-md-6" style="margin-top:-5px">

                    <div class="ld-fancy-heading mask-text">
                      <p
                        class="text-white"
                        data-split-text="true"
                        data-split-options='{"type":"lines"}'
                        data-custom-animations="true"
                        data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"300","delay":"120","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":50,"rotateZ":5},"animations":{"translateY":0,"rotateZ":0}}'>
                        <span class="ld-fh-txt">
                          Individual transportation using cars, motor bikes etc. make up up to <a href="https://www.epa.gov/greenvehicles/fast-facts-transportation-greenhouse-gas-emissions" target="_blank">60%</a>.
                          23% count for trucks used for transportation of goods and 9% for aircraft &ndash; U.S. only.
                        </span>
                      </p>

                      <p
                        class="text-white"
                        data-split-text="true"
                        data-split-options='{"type":"lines"}'
                        data-custom-animations="true"
                        data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"300","delay":"120","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":50,"rotateZ":5},"animations":{"translateY":0,"rotateZ":0}}'>
                        <span class="ld-fh-txt">
                          Taking an <strong>airplane is much worse</strong> than a car or a train.
                          Airplanes release CO2 at high-altitude and cause heat-trapping <a href="https://www.newscientist.com/article/2207886-it-turns-out-planes-are-even-worse-for-the-climate-than-we-thought/" target="_blank">contrails</a>.
                        </span>
                      </p>



                    </div>

                  </div>

                  <div class="lqd-column h5 col-md-6" style="margin-top:-5px">

                    <div class="ld-fancy-heading mask-text">
                      <p
                        class="text-white"
                        data-split-text="true"
                        data-split-options='{"type":"lines"}'
                        data-custom-animations="true"
                        data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1600","startDelay":"300","delay":"120","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":50,"rotateZ":5},"animations":{"translateY":0,"rotateZ":0}}'>
                        <span class="ld-fh-txt">
                          <ul>
                            <li><strong>Only fly</strong> for distances greater than 1000 miles.</li>
                            <li>Carbon-offset every mile you drive.</li>
                            <li>Use bus over train over car.</li>
                            <li>Cycle or walk short distances. <i>Mind the health benefits.</i></li>
                            <li>Humanity has been happy without cars for about 35,000 years.</li>
                          </ul>

                        </span>
                      </p>
                    </div>

                    <a href="https://www.youtube.com/watch?v=TkXEU5ng8rE" style="margin-top:20px;margin-left:0px" class="btn btn-naked text-uppercase fresco btn-icon-left btn-icon-xlg btn-icon-circle btn-icon-solid btn-icon-ripple font-size-13 font-weight-bold ltr-sp-1">
                      <span>
                        <span class="btn-txt text-white">The greenest <br />way to travel (8:07)</span>
                        <span class="btn-icon font-size-20"><i class="fa fa-play"></i></span>
                      </span>
                    </a>

                  </div>


                </div>

              </div>

            </div>
          </div>
        </section>

      </main>

    </div>



    <div class="modal" id="is-climate-change-real-modal">
      <div class="modal-dialog">
        <div class="modal-content">

          <div class="modal-header">
            <h4 class="modal-title">Mass extinction!? Are you serious?</h4>
          </div>

          <div class="modal-body">
            <p>
              Global warming isn't anything new: About 250 million years ago a similar event caused a mass extinction called "The Great Dying".
              Temperatures did rise by 6°C back then. Since decades, scientists monitor the rise of global temperatures.
</p>
            <p>
              In the past, scientists noticed that they were too conservative and a faster global warming became alarming. <strong>Unfortunately since mid 2019, 3 of the 7 widely accepted climate models
            are running <font color="#cc0000">red hot. Now they predict an increase in global warming by over 5°C for year 2100+</font>.</strong>
            </p>

            <img src={require("../assets/img/climate-models-running-red-hot.png")} width="100%" />
            <br /><br />

            <p>
              Scientist warn, that we need a 0t CO2 balance by 2050 to keep temperature rise below 1.5% until 2100.
            </p>

            <p>But how can we reach that goal in the next 30 years to come, when current graphs look like this?</p>

            <iframe src="https://ourworldindata.org/grapher/cumulative-co2-emissions-region?stackMode=absolute" style="width: 100%; height: 600px; border: 0px none;"></iframe>

            <p>
              This isn't something to laugh about - this is something to act uppon &nbsp;
              <strong>and mass behaviour change is the only valid answer.</strong> We need to change our own habits now.
            </p>

            <h5 class="modal-title">How would our planet look like, if we reach +5°C?</h5>

            <p>
              &nbsp;
            <iframe width="560" height="315" src="https://www.youtube.com/embed/qWoiBpfvdx0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </p>

            <ul>
              <li>Ice will vanish, rain forests will turn into deserts.</li>
              <li>Rising seas reach deep into continents, capital cities will be flooded.</li>
              <li>Racial conflicts and civil wars are inevitable.</li>
              <li><strong>Above 6°C, 95% of species, <font color="#cc0000">including mankind, will face extinction.</font></strong></li>
            </ul>

            <h5 class="modal-title">Balancing atmosphere's CO2 levels is key</h5>

            <p>
            </p>

            <p>
              Carbon is the chemical backbone of life on Earth.
              Carbon compounds regulate the Earth’s temperature,
              make up the food that sustains us, and provide energy that fuels our global economy.
          </p>

            <p>
              Changes in the carbon cycle impact the atmosphere, land and oceans.
              Extra carbon that is released by human activity, needs to go somewhere:
          </p>

            <ul>
              <li>Excess carbon in the atmosphere warms the planet and helps plants on land grow more. </li>
              <li>Excess carbon in the ocean makes the water more acidic, putting marine life in danger.  </li>
            </ul>

            <p>
              Because CO2 is the most important gas for controlling Earth’s temperature,
              it is significant that a well balanced amount of carbon dioxide stays in the atmosphere.
          </p>

            <p>
              Carbon dioxide, methane, and halocarbons are greenhouse gases
              that absorb a wide range of energy—including infrared energy (heat) emitted by the Earth—and then re-emit it.
          </p>

            <p>
              The re-emitted energy travels out in all directions, but some returns to Earth, where it heats the surface.
          </p>

            <ul>
              <li>Without greenhouse gases, Earth would be a frozen -18 degrees Celsius (0 degrees Fahrenheit).</li>
              <li>With too many greenhouse gases, Earth would be like Venus, where the greenhouse atmosphere keeps temperatures around 400 degrees Celsius (750 Fahrenheit).</li>
            </ul>

            <h5 class="modal-title">Climate is a cyclic and chaotic system</h5>

            <p>
              Carbon moves from one storage reservoir to another through a variety of mechanisms.
              For example, in the food chain, plants move carbon from the atmosphere into the biosphere through photosynthesis.
              Doing this, they produce and store sugar.
          </p>

            <p>
              Animals that eat plants digest thier sugar molecules to get energy for their bodies.
              Respiration, excretion, and decomposition release the carbon back into the atmosphere or soil, continuing the cycle.
          </p>

            <p>
              The ocean plays a critical role in carbon storage, as it holds about 50 times more carbon than the atmosphere.
              Two-way carbon exchange can occur quickly between the ocean’s surface waters and the atmosphere,
              but carbon may be stored for centuries at the deepest ocean depths.
          </p>

            <h5 class="modal-title">Mass human activity interfers with climate massively</h5>

            <p>
              Human activity has a tremendous impact on the carbon cycle. Burning fossil fuels, changing land use,
              and using limestone to make concrete all transfer significant quantities of carbon into the atmosphere.
            </p>
            <p>
              As a result, the amount of carbon dioxide in the atmosphere is rapidly rising; it is already considerably
              greater than at any time in the last 800,000 years. The ocean absorbs much of the carbon dioxide that is
              released from burning fossil fuels. This extra carbon dioxide is lowering the ocean’s pH, through a
              process called ocean acidification.
            </p>
            <p>
              Ocean acidification interferes with the ability of marine organisms (including corals, Dungeness crabs, and snails)
              to build their shells and skeletons.
            </p>

            <h5 class="modal-title">Risk of extinction caused by decreased biodiversity</h5>

            <p>
              Climate change is not the only risk to counter. Biodiversity makes it possible for humans to have a sustainable level of soils and the means to have the genetic factors in order to have food.
          </p>

            <p>
              The 2019 IPBES Global Assessment Report on Biodiversity and Ecosystem Services asserts that industrial farming is a significant factor in collapsing biodiversity.
          </p>

            <p>
              The health of humans is largely dependent on the product of an ecosystem. With biodiversity loss, a huge impact on human health comes as well.
          </p>

            <p>
              The current rate of global diversity loss is estimated to be 100 to 1000 times higher than the (naturally occurring) background extinction rate and expected to still grow in the upcoming years.
          [<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4640606" target="_blank">Science Advances, 2015</a>, <a href="http://www.fao.org/state-of-biodiversity-for-food-agriculture/en/" target="_blank">UN, 2019</a>].
          </p>

            <p>
              Human influence already made 83% of Wild Mammals and 50% of all plants disappear by now [<a href="https://www.pnas.org/content/115/25/6506" target="_blank">Bar-On, Philips, Milo, 2018</a>]
          </p>

            <p>
              Also, on the basis of mid-range climate-warming scenarios for 2050, Thomas, Cameron, Green and Bakkenes found, that 15-37% of species will be 'committed to extinction'. [PubMed, 2004]

            </p>
            <p>
              Meanwhile, we've seen climate models to have been too conservative back in 2004, so we have to expect an even higher species extinction rate.
            </p>

            <h5>
              In order to save the planet's ecosystems, every responsible and educated person, <font color="#cc0000">should act now!</font>
            </h5>

            <h6 class="modal-title">References</h6>

            <ul>
              <li><a href="https://www.noaa.gov/education/resource-collections/climate-education-resources/carbon-cycle" target="_blank">NOAA. "Carbon cycle" (2019)</a></li>
              <li><a href="https://earthobservatory.nasa.gov/features/CarbonCycle/page5.php" target="_blank">NASA. "Effects of Changing the Carbon Cycle" (2011)</a></li>
              <li><a href="https://www.bloomberg.com/news/features/2020-02-03/climate-models-are-running-red-hot-and-scientists-don-t-know-why" target="_blank">Bloomberg Green, "Climate Models Are Running Red Hot, and Scientists Don’t Know Why" (2020)</a></li>
            </ul>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default text-uppercase btn-bordered border-thin fresco btn-modal font-size-14 font-weight-bold ltr-sp-1 mb-2" data-dismiss="modal">Close</button>
          </div>

        </div>
      </div>
    </div>
  </fragment>
}