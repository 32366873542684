import { st } from "springtype/core";
import { ref } from "springtype/core/ref";
import { attr, component } from "springtype/web/component";
import { ILifecycle } from "springtype/web/component/interface";
import { tsx } from "springtype/web/vdom";
import tpl from "./index.tpl";
import { FirebaseService } from "./service/firebase";
import { inject } from "springtype/core/di";
import { FIREBASE_CONFIG } from "./config/firebase";
import { RenderReason } from "springtype/web/component/interface/irender-reason";

@component({
  tpl
})
export class HomepageComponent extends st.component implements ILifecycle {
  changeLanguage = (language: string) => {
    console.log('Change lan to', language);
  }

  shouldRender(reason: RenderReason) {
    if (reason === RenderReason.INITIAL) {
      return true;
    }
    return false;
  }

  @inject(FirebaseService, FIREBASE_CONFIG)
  firebaseService: FirebaseService;
}

st.render(<HomepageComponent />);

/**
 * 
      <footer class="main-footer bg-charade pt-70">

        <section>

          <div class="container">
            <div class="row">

              <div class="lqd-column col-md-3 col-sm-6">

                <h3 class="widget-title text-white">Company</h3>
                <ul class="lqd-custom-menu reset-ul text-white">
                  <li><a href="#">Home</a></li>
                  <li><a href="#">Product</a></li>
                  <li><a href="#">Customers</a></li>
                  <li><a href="#">Pricing</a></li>
                </ul>

              </div>

              <div class="lqd-column col-md-3 col-sm-6">

                <h3 class="widget-title text-white">Products</h3>
                <ul class="lqd-custom-menu reset-ul text-white">
                  <li><a href="#">Company</a></li>
                  <li><a href="#">Jobs</a></li>
                  <li><a href="#">Press</a></li>
                  <li><a href="#">Blog</a></li>
                </ul>

              </div>

              <div class="lqd-column col-md-3 col-sm-6">

                <h3 class="widget-title text-white">Products</h3>
                <p>
                  hello@ave.com
									<br />
                  <br />
                  290 Maryam Springs 260,
                  Courbevoie, Paris, France
									<br />
                  <br />
                  +47 213 5941 295
								</p>

              </div>

              <div class="lqd-column col-md-3 col-sm-6">

                <h3 class="widget-title text-white">Follow us</h3>
                <ul class="social-icon social-icon-md">
                  <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                  <li><a href="#"><i class="fa fa-youtube-play"></i></a></li>
                </ul>

                <h3 class="widget-title text-white">Subscribe</h3>
                <div class="ld-sf ld-sf--input-solid ld-sf--button-solid ld-sf--size-xs ld-sf--circle ld-sf--border-thin ld-sf--button-show ld-sf--button-inline">
                  <form id="ld_subscribe_form" class="ld_sf_form" action="{YOUR_MAILCHIMP_ACTION_URL}" name="mc-embedded-subscribe-form" method="post">
                    <p class="ld_sf_paragraph pr-2">
                      <input type="email" class="ld_sf_text" id="mce-EMAIL" name="EMAIL" placeholder="Your email" value="" />
                    </p>
                    <button type="submit" class="ld_sf_submit px-4">
                      <span class="submit-icon">
                        <i class="fa fa-angle-right"></i>
                      </span>
                      <span class="ld-sf-spinner">
                        <span>Sending </span>
                      </span>
                    </button>
                  </form>
                  <div class="ld_sf_response"></div>
                </div>

              </div>

            </div>
          </div>

        </section>


        <section class="bt-fade-white-015 pt-35 pb-35 mt-50">
          <div class="container">
            <div class="row">

              <div class="lqd-column col-md-6">

                <ul class="lqd-custom-menu reset-ul inline-nav">
                  <li><a href="#">Ave Guide</a></li>
                  <li><a href="#">Support</a></li>
                  <li><a href="#">Intergrations</a></li>
                  <li><a href="#">Community</a></li>
                </ul>

              </div>

              <div class="lqd-column col-md-6 text-md-right">
                <p class="my-0"><span style="font-size: 15px;">© 2020 Healing Earth Non Profit Ltd., India. Made with 💚.</span></p>
              </div>

            </div>
          </div>
        </section>

      </footer>

 */